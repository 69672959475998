import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout/Layout";
import Button from "../components/Button";
import { SEO } from "../components/Seo";
import seoData from "../data/seoData.json";

const Contact = () => (
  <Layout>
    <div className="absolute z-0 w-[100vw] max-w-[100%]">
      <div className="bgfilter" />
      <StaticImage
        src="../images/whypeople_imagen2.jpg"
        objectFit="cover"
        alt="whypeople"
        style={{
          width: "100vw",
          maxWidth: "100%",
          position: "fixed",
          zIndex: "-5",
          minHeight: "100vh",
        }}
      />
    </div>
    <div className="min-h-[calc(100vh-10.5rem)] py-4 relative z-5">
      <div className="max-w-md lg:max-w-lg xl:max-w-xl 2xl:max-w-3xl 3xl:max-w-4xl mx-auto md:py-12">
        <div className="flex flex-row relative z-5">
          <div className="border-b-2 border-b-solid border-b-primary-default w-[10%]" />
          <h1 className="text-4xl xl:text-5xl 2xl:text-6xl 4xl:text-7xl 7xl:text-8xl font-bold leading-none text-center text-primary-darkest w-[80%]">
            Contáctanos
          </h1>
          <div className="border-b-2 border-b-solid border-b-primary-default w-[10%]" />
        </div>
      </div>

      <section className="w-5/6 md:w-4/6 4xl:max-w-7xl mx-auto my-12 py-12 px-4 bg-primary-lighter rounded-lg text-center">
        <h2 className="text-primary-darkest text-lg sm:text-xl lg:text-2xl 2xl:text-3xl font-semibold">
          ¿Listo para hacer crecer tu negocio?
        </h2>
        <p className="text-primary-darkest mt-4 lg:mt-8 text-sm md:text-lg xl:text-xl 3xl:text-2xl p-2 text-left lg:px-12">
          Si tu también crees que las empresas para ser más rentables y estar
          mejor preparadas para superar sus retos deben gestionar el activo más
          importante que tienen, sus equipos, contáctanos...
        </p>
        <Button
          size="lg"
          className="mx-4 mt-4 lg:mt-8 text-sm md:text-lg lg:text-xl xl:text-2xl 3xl:text-3xl"
        >
          <a
            className="text-white z-30 hover:text-white"
            href="mailto:info@whypeople.es"
          >
            Contactar
          </a>
        </Button>
        <p className="mt-4 text-xs md:text-md lg:text-lg xl:text-xl 3xl:text-2xl text-primary-darkest">
          ✉
          <span className="font-bold text-primary-darker">
            {" "}
            info@whypeople.es
          </span>
        </p>
        <p className="mt-4 text-xs md:text-md lg:text-lg xl:text-xl 3xl:text-2xl text-primary-darkest">
          ✆
          <span className="font-bold text-primary-darker">
            {" "}
            +34 615 900 086
          </span>
        </p>
      </section>
    </div>
  </Layout>
);

export default Contact;

export const Head = () => (
  <SEO
    title="Contacta con tu consultora de RR.HH | WhyPeople"
    description="¿Listo para hacer crecer tu negocio? Contacta con nuestro equipo experto en Consultoría de Recursos Humanos, organización y dirección."
    keywords={["WhyPeople contacto"]}
  >
    <script type="application/ld+json">{JSON.stringify(seoData)}</script>
  </SEO>
);
